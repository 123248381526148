<template>
  <header>
    <div class="header-text">
      <h3>Petr Bačík</h3>
      <h1>Tvorba webových stránek</h1>
      <p>
        Již několik let se věnuji tvorbě webových stránek, grafickému designu a
        online marketingu. Moje zkušenosti mi umožňují vytvářet nejen esteticky
        atraktivní weby, ale také funkční řešení, která přinášejí reálné
        výsledky. Mým cílem je pomáhat firmám získávat více zákazníků
        prostřednictvím efektivní online prezentace, optimalizace webů a
        promyšlených marketingových strategií. Každý projekt přizpůsobuji
        konkrétním potřebám klienta, aby jejich značka oslovila správné publikum
        a přinášela maximální úspěch.
      </p>
      <!-- <br />
      <p>
        Nabízím komplexní servis v oblasti tvorby webových stránek a grafických
        návrhů. Postarám se o veškerou správu webu, včetně formulářů, domény a
        web hostingu.
      </p>
      <br />

      <p>
        Nechte si vytvořit webovou stránku, která bude vaším nejlepším online
        obchodníkem. Kontaktujte mě a zjistěte, jak můžeme společně vybudovat
        vaši novou digitální tvář!
      </p> -->

      <a
        href="mailto:info@weby-peba.cz?subject=Dotaz%20na%20šablonu%20Detailing&body=Dobrý%20den,%0A%0Amám%20zájem%20o%20vaše%20služby.%20Můžete%20mi%20poskytnout%20další%20informace?&bcc=pebaweby@gmail.com"
        ><button class="demand">Nezávazná poptávka</button></a
      >
    </div>
    <Teleport to="body">
      <div class="background">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </Teleport>
  </header>
  <!-- <div class="services-heading">
    <h1 class="background-heading-main">Služby</h1>
    <h1 class="background-heading">Služby</h1>
  </div> -->
  <section class="services">
    <div class="service-box" data-aos="fade-down" data-aos-once="true">
      <img
        src="@/assets/web-page-svgrepo-com.png"
        alt="web-icon"
        width="45px"
        height="45px"
      />
      <h3>Tvorba webových stránek</h3>
      <p>
        Moderní a responzivní webové stránky na míru s důrazem na design a
        funkčnost.
      </p>
    </div>
    <div
      class="service-box"
      data-aos="fade-down"
      data-aos-once="true"
      data-aos-delay="50"
    >
      <img
        src="@/assets/design-svgrepo-com (1).png"
        alt="web-icon"
        width="45px"
        height="45px"
      />
      <h3>Grafický design</h3>
      <p>
        Kreativní grafické návrhy pro vizuální identitu, tiskoviny a online
        materiály.
      </p>
    </div>
    <div
      class="service-box"
      data-aos="fade-down"
      data-aos-once="true"
      data-aos-delay="100"
    >
      <img
        src="@/assets/logo-design.png"
        alt="web-icon"
        width="45px"
        height="45px"
      />
      <h3>Tvorba firemního loga</h3>
      <p>Unikátní a profesionální loga pro silnou identitu vaší značky.</p>
    </div>
    <div
      class="service-box"
      data-aos="fade-down"
      data-aos-once="true"
      data-aos-delay="150"
    >
      <img
        src="@/assets/statamic-svgrepo-com.png"
        alt="web-icon"
        width="45px"
        height="45px"
      />
      <h3>Tvorba webových aplikací</h3>
      <p>
        Webové aplikace přizpůsobené vašim potřebám, které zefektivní vaše
        podnikání.
      </p>
    </div>

    <div
      class="service-box"
      data-aos="fade-down"
      data-aos-once="true"
      data-aos-delay="200"
    >
      <img src="@/assets/ui.png" alt="web-icon" width="45px" height="45px" />
      <h3>UX & UI Design</h3>
      <p>
        Intuitivní a atraktivní uživatelská rozhraní s důrazem na snadné
        použití.
      </p>
    </div>
    <div
      class="service-box"
      data-aos="fade-down"
      data-aos-once="true"
      data-aos-delay="250"
    >
      <img
        src="@/assets/social-media-marketing.png"
        alt="web-icon"
        width="45px"
        height="45px"
      />
      <h3>Online marketing</h3>
      <p>
        Strategie pro digitální marketing, SEO, sociální sítě a PPC kampaně pro
        maximální viditelnost a dosažení vašich obchodních cílů.
      </p>
    </div>
  </section>
  <section class="collab">
    <h2>Jak probíhá spolupráce?</h2>
    <div class="collab-box-container">
      <div class="box" data-aos="fade-right">
        <div class="icon">
          <img
            src="@/assets/number-one.png"
            alt="number-one"
            width="85px"
            height="85px"
          />
        </div>
        <div class="text">
          <h3>Konzultace a analýza potřeb</h3>
          <p>
            Spolupráce začíná úvodním setkáním, ať už osobně nebo online, kde
            probereme vaše cíle a představy o webových stránkách. Na základě
            této konzultace lépe pochopím vaše požadavky a navrhnu řešení, které
            nejlépe vyhovuje Vašim obchodním potřebám a očekáváním.
          </p>
        </div>
      </div>
      <div class="box" data-aos="fade-right">
        <div class="icon">
          <img
            src="@/assets/number-2.png"
            alt="number-two"
            width="85px"
            height="85px"
          />
        </div>
        <div class="text">
          <h3>Tvorba návrhu a vývoj</h3>
          <p>
            Po shromáždění informací vytvořím návrh webových stránek. Tento
            design vám představím k posouzení, abyste mohli poskytnout zpětnou
            vazbu a zajistit, že odpovídá vaší vizi. Jakmile je návrh schválen,
            začnu s vývojem stránek. Celý proces je provázen průběžnou
            komunikací, aby byl zajištěn hladký postup a jasná koordinace.
          </p>
        </div>
      </div>
      <div class="box" data-aos="fade-right">
        <div class="icon">
          <img
            src="@/assets/number-3.png"
            alt="number-three"
            width="85px"
            height="85px"
          />
        </div>
        <div class="text">
          <h3>Testování a optimalizace</h3>
          <p>
            Po dokončení vývoje přichází důkladné testování. Webové stránky
            otestuji na různých zařízeních a v různých prohlížečích, abych
            zajistil správnou funkčnost, kompatibilitu a optimální uživatelský
            zážitek. Během této fáze se rovněž provádí drobné úpravy a
            optimalizace.
          </p>
        </div>
      </div>
      <div class="box" data-aos="fade-right">
        <div class="icon">
          <img
            src="@/assets/number-four.png"
            alt="number-four"
            width="85px"
            height="85px"
          />
        </div>
        <div class="text">
          <h3>Nasazení a spuštění webu</h3>
          <p>
            V závěrečné fázi se vše připraví na ostré spuštění. Po posledních
            kontrolách a finálních úpravách váš web oficiálně uvedu do provozu.
            Tento krok představuje důležitý okamžik, kdy se váš online projekt
            dostává k vašim zákazníkům a otevírá nové příležitosti pro Vaše
            podnikání.
          </p>
        </div>
      </div>
      <div class="box" data-aos="fade-right">
        <div class="icon">
          <img
            src="@/assets/number-5.png"
            alt="number-five"
            width="85px"
            height="85px"
          />
        </div>
        <div class="text">
          <h3>Online marketing</h3>
          <p>
            Po spuštění Vašich webových stránek je ideální čas začít s online
            marketingem. Web je připraven přivítat návštěvníky a podpora
            prostřednictvím SEO, PPC kampaní, sociálních sítí nebo e-mailového
            marketingu může rychle zvýšit jeho návštěvnost. Čím dříve zahájíte
            marketingové aktivity, tím rychleji můžete oslovit svou cílovou
            skupinu a začít naplno využívat potenciál Vašeho nového webu.
          </p>
        </div>
      </div>
    </div>
  </section>
  <section class="why">
    <span class="why-container1">
      <h2>Individuální přístup</h2>
      <p>Každý projekt přizpůsobuji vašim specifickým potřebám</p>
    </span>
    <span class="why-container2">
      <h2>Kreativita a inovace</h2>
      <p>Přináším jedinečné nápady a řešení, která vás odliší od konkurence</p>
    </span>
    <span class="why-container3">
      <h2>Kvalita a preciznost</h2>
      <p>Dbám na každý detail, aby vše fungovalo hladce a profesionálně</p>
    </span>
    <span class="why-container4">
      <h2>Dodržování termínů</h2>
      <p>Respektuji čas klientů a dodávám projekty vždy včas</p>
    </span>
  </section>
  <footer>
    <h2><a href="/">Weby PeBa</a></h2>
    <h3>Všechna práva vyhrazena © 2024</h3>
  </footer>
</template>

<script>
import "aos/dist/aos.css";
import AOS from "aos";
export default {
  mounted() {
    AOS.init(); // Inicializace AOS
  },
};
</script>
<style scoped>
header {
  display: flex;
  position: relative;
  line-height: 2.1rem;
  width: 60%;
}
.header-text {
  z-index: 2;
  padding-left: 10vw;
}

/* .header-photo {
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
  position: absolute;
  height: 100%;
  width: 50%;
  right: 0;
  z-index: 1;
} */
header h1 {
  padding-bottom: 20px;
  line-height: 3rem;
  text-transform: uppercase;
}
header p {
  line-height: 1.8rem;
}

/* SERVICES */
.services {
  width: 85%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  margin: 0 auto;
}
.service-box {
  width: 400px;
  height: 280px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  padding-inline: 2rem;
  margin-top: 30px;
  background-color: var(--main-black-box);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 20px;
}
.service-box p {
  font-size: 18px;
}
.service-box h3 {
  font-size: 1.5rem;
}
.background-heading {
  color: var(--main-black);
  -webkit-text-stroke: 2px var(--main-green);
  opacity: 0.2;
  font-size: 2.4rem;
  position: absolute;
  bottom: 0px;
}
.background-heading-main {
  z-index: 2;
  position: relative;
}
.services-heading {
  position: relative;
  padding-left: 10vw;
  padding-top: 30px;
}
.collab {
  width: 100%;
}
.collab h2 {
  padding-left: 10vw;
  margin-top: 5vh;
}
.collab p {
  font-size: 18px;
}
.collab .box {
  width: 50%;
  margin-top: 5vh;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  text-align: justify;
}
.collab .box .icon {
  padding-right: 2vw;
}
.collab .box {
  background-color: var(--main-black-box);
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 20px;
}
.collab-box-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.why {
  margin: 0 auto;
  width: 80%;
  padding-top: 50px;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(2, 1fr);
  row-gap: 5vh;
}
.why-headding-div h1 {
  font-size: 2rem;
  padding-left: 10vw;
  padding-top: 20px;
}
.why-container1,
.why-container2,
.why-container3,
.why-container4 {
  text-align: center;
}
/* .why-container2 {
  grid-row-start: 2;
  grid-column-start: 2;
}
.why-container3 {
  grid-row-start: 3;
  grid-column-start: 1;
}
.why-container4 {
  grid-row-start: 4;
  grid-column-start: 2;
} */

@media screen and (max-width: 80px) {
  .services {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 1100px) {
  header {
    width: 90%;
  }
}
@media screen and (max-width: 1000px) {
  .services {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 950px) {
  .why {
    display: flex;
    flex-direction: column;
  }
}
@media screen and (max-width: 900px) {
  .services {
    width: 100%;
  }
  .collab .box {
    width: 70%;
  }
}
@media screen and (max-width: 700px) {
  .services {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 600px) {
  .service-box {
    padding: 20px;
  }
  .collab .box {
    width: 90%;
  }
}
@media screen and (max-width: 500px) {
  .collab .box {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .collab h2 {
    padding: 0;
    text-align: center;
  }
}
@media screen and (max-width: 450px) {
  .service-box {
    width: 350px;
  }
}
</style>
